import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/components/Home.vue'; // Example component
import HelloWorld from '@/components/HelloWorld.vue'; // Example component


Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
        path: '/',
        name: 'HelloWorld',
        component: HelloWorld,
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
    },
    // Add more routes as needed
  ],
});
