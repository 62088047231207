<template>
  <div>
    <v-app>
      <v-main>
        <v-container>
          <v-row>
            <v-col>
              <v-btn color="primary">Primary Button</v-btn>
            </v-col>
            <v-col>
              <v-alert type="success" dismissible>
                This is a success alert!
              </v-alert>
            </v-col>
            <v-col>
              <v-text-field label="Enter your name" outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <div class="login-part">
              <div class="login-info d-flex justify-center">
                <div class="loginWrapper">
                  <v-form
                    ref="form"
                    :model="model"
                    :rules="rules"
                    @submit.prevent="login"
                    lazy-validation
                    data-test="login-form"
                  >
                    <div class="input-filled">
                      <div class="user-email-filled">
                        <label for="">Email</label>
                        <ExTextInput
                          type="email"
                          v-model="model.email"
                          :rules="rules.email"
                          placeholder="Enter your email"
                          class="mb-4"
                        />
                      </div>
                      <div class="user-email-filled">
                        <label for="">Password</label>
                        <ExTextInput
                          type="password"
                          v-model="model.password"
                          :rules="rules.password"
                          placeholder="Enter your password"
                          class="mb-5"
                        />
                      </div>
                      <div class="login-bottom my-5">
                        <router-link
                          to="/forgot-password"
                          class="text-justify forgot_pass_wrp forgot-detail pa-2"
                        >
                          Forgot Password
                        </router-link>
                      </div>
  
                      <!-- Vuetify Login Button -->
                      <v-btn
                        color="primary"
                        class="mt-4"
                        @click="login"
                      >
                        Login
                      </v-btn>
                    </div>
                  </v-form>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
   
  </div>
</template>

<script>
import ExTextInput from "@/components/ExTextInput.vue";
export default {
  components: { ExTextInput },
  name: "HomePage",
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
      rules: {
        email: [
          (v) => !!v || "E-mail is required",
        ],
        password: [(v) => !!v || "Password is required"],
      },
      rememberLogin: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.checkAutofill();
  },
  methods: {
    closeError() {
      this.$emit("closeError");
    },
    checkAutofill() {
      const els = this.$el.querySelectorAll(
        'input[type="email"], input[type="password"]'
      );
      els.forEach((el) => {
        el.addEventListener("animationstart", (event) => {
          if (event.animationName === "onAutoFillStart") {
            const label = el.parentElement.querySelector("label");
            if (label) {
              label.classList.add("v-label--active");
            }
          }
        });
      });
    },
    async login() {
      this.$emit("closeError");

      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      this.$emit("login", this.model);
      console.log("login", this.model);
    },
    onSelectAccount(account) {
      this.$emit("onSelectAccount", account);
    },
  },
};
</script>

<style>
.login-bottom .ex-checkbox .label {
  margin-left: 12px !important;
}
.login-ripple-color {
  color: #F2EDFF;
}
.forgot_pass_wrp {
  border-radius: 8px;
}
.forgot_pass_wrp:hover {
  background-color: #F4F4F4;
}
.loginWrapper {
  max-width: 380px;
  width: 100%;
}
.accessAccount {
  color: black !important;
  background-color: #d6faf5 !important;
  margin-bottom: 40px !important;
  padding: 20px 16px !important;
}
.accessAccount .el-alert__title {
  font-size: 20px !important;
  color: #000;
  line-height: 20px;
  font-weight: 400;
}
.text_error {
  color: red;
}
.multipleAccount {
  width: 100%;
  max-width: 465px;
  margin: 0 auto;
}
.accounts-box {
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  border-radius: 6px;
  padding: 20px 20px 20px 16px;
}
.select-button {
  color: #ffffff !important;
  background-color: #00c3a9 !important;
  width: 180px;
  padding: 3px 0px !important;
  border-radius: 8px !important;
  font-size: 18px !important;
  border: 1px solid #000000 !important;
  width: 160px;
}
.accounts-box h3 {
  font-size: 18px;
  line-height: 18px;
}
.el-link.el-link--default {
  color: #6941c6;
}
.el-link.el-link--default:hover {
  color: #6941c6;
  text-decoration: none;
}

.el-link.is-underline:hover:after {
  border-bottom: none !important;
}
.theme--light.v-btn.v-btn--has-bg.btn-submit {
  width: 100%;
  background-color: #7f56d9 !important;
  color: white !important;
  font-size: 15px;
  font-weight: 600;
  text-transform: math-auto;
  margin-bottom: 20px;
  padding: 24px;
  border-radius: 10px;
}
.input-filled {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}
.input-filled label {
  display: block;
  color: #344054;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.input-filled fieldset {
  color: #d0d5dd !important;
  height: 45px;
  border-radius: 8px !important;
}
.input-filled .v-text-field__slot {
  height: 40px;
}
.v-input__control {
  position: relative;
}

.v-text-field__details {
  position: absolute;
  bottom: -20px;
  left: 0;
}
.login-bottom {
  display: flex;
  justify-content: space-between;
}
.login-bottom label {
  color: #344054 !important;
  font-weight: 500;
}
.login-bottom input {
  opacity: 1 !important;
}
.login-bottom label {
  margin: 0px !important;
}
.forgot-detail {
  padding-top: 5px !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #6941C6;
}
.login-part {
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.welcome-subtitle {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  font-weight: 700;
  text-align: center;
}
</style>
