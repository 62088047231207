<template>
    <div class="ex_text_input" :class="{ 'show-overflow': clearText.length > 0 && value && value.length > 0 }">
      <label class="main_label" :class="{'is-disabled': disabled}">{{ label }}</label>
      <v-text-field
          class="input-search"
          :class="{
             'large-left': icon,
             'medium-left': symbol,
             'x-large-left': (dropdownSide === 'left' && dropdownItems.length > 0),
             'hide_label': value
          }"
          :label="(!ghostText) && showLabel ? placeholder : undefined"
          :placeholder="(ghostText) ? ghostText : ''"
          outlined
          height="40px"
          :error="error"
          :disabled="disabled"
          :hide-details="localHideDetails"
          :error-messages="errorMessage"
          @update:error="updateError"
          @input="setLocalInputAndEmitValue"
          v-model="localValue"
          :value="value"
          :rules="rules"
          :required="required"
          :type="type"
          data-testid="search-input"
          @focus="onFocus"
          @blur="onBlur"
        />
    </div>
  </template>
  <script>

  export default {
    name: "ExTextInput",
    data() {
      return {
        selectedCode: "",
        showLabel: true,
        localValue: "",
        localHideDetails: false,
      }
    },
    watch: {
      // Whenever the parent changes the prop, we sync it with the local data property
      value(newVal) {
        this.localValue = newVal;
      }
    },
    mounted () {
      if (this.dropdownItems && this.dropdownItems.length > 0) {
        this.selectedCode = this.dropdownItems[0]
      }
    },
    methods: {
      clearValue() {
        this.$emit("input", "")
      },
      setLocalInputAndEmitValue($event) {
        this.localValue = $event;
        this.$emit('input', this.localValue);
      },
      updateError(data) {
        if (data) {
          this.localHideDetails = false;
        } else {
          this.localHideDetails = true;
        }
      },
      onFocus(event) {
        this.showLabel = true;
        this.$emit('onFocus', event);
      },
      onBlur(event) {
        this.localValue?.length > 0 ? this.showLabel = false : true;
        this.$emit('onBlur', event);
      }
    },
    props: {
      value: {
        type: String,
        default: ""
      },
      label: {
        type: String,
        default: ""
      },
      placeholder: {
        type: String,
        default: ""
      },
      symbol: {
        type: String,
        default: ""
      },
      icon: {
        type: String,
        default: ""
      },
      error: {
        type: Boolean,
        default: false
      },
      errorMessage: {
        type: String,
        default: ""
      },
      disabled: {
        type: Boolean,
        default: false
      },
      dropdownItems: {
        type: Array,
        default() {
          return [];
        },
      },
      dropdownSide: {
        type: String,
        default: "left"
      },
      rules: {
        type: Array,
        default: () => []
      },
      required: {
        type: Boolean,
        default: false
      },
      ghostText: {
        type: String,
        default: ""
      },
      type: {
        type: String,
        default() {
          return "";
        },
      },
      clearText: {
        type: String,
        default: ""
      },
      showDownUpChevron: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false,
      }
    }
  }
  </script>
  <style>
  .ex_text_input .v-text-field__details {
    position: absolute;
    left: 0 !important;
    bottom: -20px !important;
  }
  .ex_text_input .v-input__slot .v-label {
    padding-left: 4px !important;
  }
  .ex_text_input .input-search {
    margin-top: 5px !important;
  }
  .ex_text_input .input-search.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 0px !important;
    align-self: center !important;
    /* margin-left: 6px; */
  }
  .ex_text_input .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
  }
  .ex_text_input .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
  }
  
  .ex_text_input .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin: auto;
    overflow: hidden !important;
  }
  .ex_text_input .code-select-wrapper .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
    overflow: hidden !important;
  }
  .ex_text_input.show-overflow .v-text-field .v-input__control .v-input__slot .v-input__append-inner{
    overflow: visible !important;
  }
  .ex_text_input.input-dirty .v-text-field .v-input__control .v-input__slot {
    background-color: #F2EDFF !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border-radius: 8px;
  }
  
  .ex_text_input .v-text-field .v-input__control .v-input__slot {
    background-color: white !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  
  .ex_text_input .v-text-field .v-input__control .v-input__slot label {
    margin-left: -4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #98A2B3;
    top: 42%;
    transform: translateY(-50%);
  }
  .ex_text_input .hide_label .v-input__control .v-input__slot label {
    display: none !important;
  }
  .ex_text_input .v-input--is-focused .v-input__control .v-input__slot label {
    display: flex !important;
  }
  .ex_text_input .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin-left: 8px !important;
    top: -4px;
    left: -12px !important;
    background: white;
    font-size: 10px;
    font-weight: 400;
    padding-right: 4px;
    padding-left: 8px;
    color: #667085 !important;
    display: flex;
    align-items: center;
    height: 10px !important;
  }
  .ex_text_input .medium-left.v-text-field .v-input__control .v-input__slot label.v-label--active {
    left: -32px !important;
    padding-left: 4px;
    padding-right: 4px;
  }
  .ex_text_input .x-large-left.v-text-field .v-input__control .v-input__slot label.v-label--active {
    left: -76px !important;
    padding-left: 4px;
    padding-right: 8px;
  }
  .ex_text_input .large-left.v-text-field .v-input__control .v-input__slot label.v-label--active {
    left: -38px !important;
    padding-left: 4px;
    padding-right: 4px;
  }
  .ex_text_input .v-text-field__details {
    margin: 2px !important;
  }
  .ex_text_input.input-dirty .v-input__control .v-input__slot fieldset {
    border: 1px solid #D6BBFB;
    border-radius: 8px;
  }
  .ex_text_input .v-input__control .v-input__slot fieldset {
    border: 1px solid #D0D5DD;
    border-radius: 8px;
  }
  .ex_text_input .v-input--is-focused fieldset {
    border: 1px solid #D6BBFB !important;
    box-shadow: 0px 0px 0px 4px #9E77ED40;
    border-radius: 8px;
  }
  .ex_text_input .v-input--is-focused fieldset legend,
  .ex_text_input .v-input__slot fieldset legend{
    width: 0px !important;
  }
  .ex_text_input .filter-input-search.v-text-field--outlined.v-input--is-focused fieldset {
    box-shadow: 0px 0px 0px 4px #F044383D !important;
    border-radius: 8px;
  }
  .ex_text_input .v-input--is-disabled fieldset {
    border: 1px solid #101828;
  }
  .ex_text_input .v-input--is-disabled .v-input__control .v-input__slot label {
    color: #d6d9dd;
  }
  .ex_text_input .error--text fieldset {
    border: 1px solid #FDA29B !important;
    box-shadow: none !important;
  }
  .ex_text_input .error--text .v-input__control .v-input__slot label {
    color: #98A2B3 !important;
  }
  .ex_text_input .v-text-field.v-text-field--enclosed .v-text-field__details {
    color: #D92D20 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    margin-top: 8px !important;
  }
  .ex_text_input .v-input--is-focused.v-input--has-state fieldset {
    border: 1px solid #FDA29B;
    box-shadow: 0px 0px 0px 4px #F044383D !important;
    border-radius: 8px;
  }
  .ex_text_input .code-select-wrapper {
    min-width: 61px;
    height: 36px;
    position: relative;
    top: -10px;
  }
  .ex_text_input .code-select .v-text-field__details {
    display: none;
  }
  .ex_text_input .code-select.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
    border: none;
  }
  .ex_text_input .code-select.v-text-field.v-input--is-focused>.v-input__control>.v-input__slot:after {
    transform: scaleX(0);
  }
  .ex_text_input .code-select.v-text-field.v-input--is-focused>.v-input__control>.v-input__slot {
    padding: 0px;
  }
  .ex_text_input .code-select.theme--light.v-text-field>.v-input__control>.v-input__slot {
    padding-left: 0px !important;
    padding-right: 0px !important;
    color: #344054;
    font-weight: 400;
    font-size: 16px;
  }
  .ex_text_input .v-select__selection--comma {
    overflow: visible;
  }
  .ex_text_input .v-text-field__slot {
    height: 40px !important;
  }
  .ex_text_input .v-input__append-inner .v-input__icon.v-input__icon--append {
    margin-top: -2px !important;
  }
  .ex_text_input .v-messages__message {
    font-size: 14px !important;
    color: #D92D20 !important;
  }
  @keyframes custom-animation {
    from {
      top: -100%;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
  .ex_text_input .v-messages .v-messages__message {
    animation: custom-animation 0.3s ease-in-out forwards !important;
  }
  .ex_text_input .clear-section .clear-button {
    padding-left: 4px !important;
    padding-right: 2px !important;
    border-radius: 8px !important;
  }
  .ex_text_input .clear-section .clear-button:hover::before  {
    background-color: white !important;
  }
  .ex_text_input .clear-section .clear-button:not(:hover)::before {
    background-color: white !important;
  }
  .ex_text_input .clear-section .clear-button .v-btn__content,
  .ex_text_input .clear-section .clear-button:hover .v-btn__content{
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 24px !important;
    color: #666666 !important;
    margin-right: 6px !important;
    white-space: nowrap !important;
    text-transform: none !important;
    letter-spacing: normal;
  }
  .ex_text_input .clear-section .clear-button .v-btn__content i {
    margin-left: 4px !important;
    color: #666666
  }
  </style>
  <style scoped>
  .ex_text_input .prepend-symbol {
    color: #344054 !important;
    font-size: 16px !important;
    font-weight: 400;
  }
  .ex_text_input .prepend-symbol.is_disabled {
    color: #d6d9dd !important;
  }
  .ex_text_input .main_label {
    color: #344054;
    font-weight: 500;
    font-size: 14px;
  }
  .ex_text_input .main_label.is-disabled {
    color: #d6d9dd;
  }
  .ex_text_input .filter_gray {
    filter: saturate(0%) !important;
  }
  .ex_text_input .clear-section {
    height: 32px;
    position: relative;
    top: 0px;
  }
  .ex_text_input .clear-section .text {
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
    color: #666666;
    margin-right: 6px;
    white-space: nowrap !important;
  }
  .ex_text_input .clear-section.clear-disabled {
    display: none !important;
  }
  </style>